import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  includesDescription?: string
  includesLabel?: string
  termsDescription?: string
  termsLabel?: string
}

export const IntroOffer = memo(function IntroOffer({
  cta,
  description,
  includesDescription,
  includesLabel,
  termsDescription,
  termsLabel,
}: Props) {
  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {description ? (
          <Fade bottom distance="3.75rem">
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </Fade>
        ) : null}
        {termsDescription ? (
          <Fade bottom distance="3.75rem">
            <Terms>
              {termsLabel ? <TermLabel>{termsLabel}</TermLabel> : null}
              <TermsDescription
                dangerouslySetInnerHTML={{ __html: termsDescription }}
              />
            </Terms>
          </Fade>
        ) : null}
      </LeftSide>
      <RightSide>
        <Wrap>
          {includesLabel ? <Label>{includesLabel}</Label> : null}
          {includesDescription ? (
            <Description
              dangerouslySetInnerHTML={{ __html: includesDescription }}
            />
          ) : null}
          {cta
            ? cta.map((item, index) => <CTA key={index} {...item} arrow />)
            : null}
        </Wrap>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  max-width: 70.25rem;
  margin: 0 auto 9.75rem;
  padding: 0 1.5rem;

  @media (max-width: 1023px) {
    margin-bottom: 7.5rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 12vh 8vw 0 0;

  @media (max-width: 767px) {
    width: 100%;
    padding: 3.75rem 0 0;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  ul {
    list-style: initial;
    margin: 1.25rem 1rem 0;
  }
`

const Terms = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 3.75rem;
  padding-top: 3.75rem;
`

const TermsDescription = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.625rem;
  ul {
    list-style: initial;
    margin: 1.25rem 1rem 0;
  }
`

const TermLabel = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const RightSide = styled.div`
  width: 50%;
  padding: 12vh 0 12vh 8vw;
  position: relative;
  &:before {
    content: '';
    width: 1000%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-top: 3.75rem;
    padding: 2.25rem 1.5rem;
    &:before {
      display: none;
    }
  }
`

const Wrap = styled.div`
  position: relative;
  z-index: 2;
`

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  margin-top: 4rem;
`
