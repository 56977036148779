import styled from '@emotion/styled'
import { Gallery } from 'app/components/Gallery'
import { Hero } from 'app/components/Hero'
import { IntroOffer } from 'app/components/IntroOffer'
import { OtherOffers } from 'app/components/OtherOffers'
import { SEO } from 'app/components/SEO'
import { VisualBenefits } from 'app/components/VisualBenefits'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function OfferPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero variant="compact" {...context.heroProps} />
      ) : null}
      {context.introOfferProps ? (
        <IntroOffer {...context.introOfferProps} />
      ) : null}
      {context.galleryProps ? (
        <Gallery separator={false} {...context.galleryProps} />
      ) : null}
      {context.visualBenefitsProps ? (
        <VisualBenefits separator={false} {...context.visualBenefitsProps} />
      ) : null}
      {context.otherOffersProps ? (
        <OtherOffers {...context.otherOffersProps} />
      ) : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
